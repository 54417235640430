<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import stepsMixin from '@/mixins/stepsMixin'
export default {
	mixins: [stepsMixin],
	props: ['client'],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
	},
	data() {
		return {
			name: 'analisis_riesgo_financiero',
			form: {
				aspectosRelevantes: '',
				comentariosSobreSolvenciaFinanciera: '',
				tablaVencimientosDisponible: '',
				comentariosSobreDeuda: '',
				numeroDeBancos: '',
				manejoCobertura: '',
				informacionAdicionalRelevante: '',
				comentarioManejoCobertura: '',
				comentarioInformacionAdicionalRelevante: '',
				// ============
			},
			tablaVencimientosForm: [
				{
					nombre: '',
					importe: '',
					ano: '',
					query: ''
				}
			],
			tablaVencimientos: []
		}
	},
	computed: {
		showTableList() {
			let data = this.tablaVencimientos.filter(sh => {
				return sh.nombre && sh.ano
			})
			return data
		},

		...mapGetters({
			_eeffByClient: 'arceEeff/eeffByCliente'
		})
	},
	watch: {
		'client': {
			handler(nVal) {
				this.fillForm()
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		getClients() {
			let children = this.client.children.map(ch => {
				return ch.name
			})
			return children
		},
		fillForm() {
			let data = {}
			if(this.client.arce.hasOwnProperty(this.name)) {
				data = {...this.client.arce[this.name]}
			}

			if(!Object.keys(data).length) {
				this.$set(this, 'form', {
					aspectosRelevantes: '',
					comentariosSobreSolvenciaFinanciera: '',
					tablaVencimientosDisponible: '',
					comentariosSobreDeuda: '',
					numeroDeBancos: '',
					manejoCobertura: '',
					informacionAdicionalRelevante: '',
					comentarioManejoCobertura: '',
					comentarioInformacionAdicionalRelevante: '',
				})
				this.$set(this, 'tablaVencimientos', [
					{
						nombre: '',
						importe: '',
						ano: '',
						query: ''
					}
				])

				return 
			}
			this.$set(this, 'tablaVencimientos', [...data.tablaVencimientos] )
			delete data['tablaVencimientos']
			this.$set(this, 'form', data)
		},
		getAnosList(data) {
			let list = []
			data.forEach(element => {
				if(!list.includes(element.ano)) {
					list.push(element.ano)
				}
			});
			return list
		},

		sumTotal(item, key) {
			let sum = item.reduce((a, c) => {
				let obj = {}
				obj[key] = a[key] + c[key]
				return obj
			})

			return sum[key]
		},	

		sumByName(item, names, key) {
			let items = item.filter(i => names.includes(i.nombre))
			let sum = items.reduce((a, c, index) => {
				let obj = {}
				// obj[key] = a[key] + c[key]
				// return obj
				obj[key] = a[key] + c[key]

				return obj
			})

			return sum[key]
		},
		setEeff() {
			this.eeffLoading = true
			let eeff = this._eeffByClient(this.client.id)
			if(!eeff) {
				return
			}
			setTimeout(() => {
				this.$set(this, 'eeff', {...eeff})
				this.eeffLoading = false
				this.eeffSuccess = true
				this.save()
			}, 2000)
		},
		
		calcFill() {
			let fields = Object.keys(this.form)
		
			if(this.form.manejoCobertura == 'NO') {
				this.form.comentarioManejoCobertura = ''
				let index = fields.indexOf('comentarioManejoCobertura')
				fields.splice(index, 1)
			}
			if(this.form.informacionAdicionalRelevante == 'NO') {
				this.form.comentarioInformacionAdicionalRelevante = ''
				let index = fields.indexOf('comentarioInformacionAdicionalRelevante')
				fields.splice(index, 1)
			}

			let points = 100 / fields.length

			let fill = 0
			fields.forEach(f => {
				if(this.form[f]) {
					fill += points
				}
			})

			return Math.round(fill)
		},
		
		save() {
			let vm = this
			let group = {...this.client}
			group.arceFill[this.name] = this.calcFill()
			group.arce[this.name] =  Object.assign({...this.form}, {tablaVencimientos: [...this.tablaVencimientos]})
			vm._updateArce(group)
		},

		addNew() {
			this.tablaVencimientosForm.push(
				{
					nombre: '',
					importe: '',
					ano: '',
					query: ''
				}
			)
		},
		deleteItem(index) {
			this.tablaVencimientosForm.splice(index, 1)
		},
		showModal(id) {
			if(document.querySelectorAll(`${id}`).length > 1) {
				$(`body > ${id}`).remove()
				this.showModal(id)
			} else {
				$(id).appendTo("body").modal('show')

			}
		},

		showTablaForm() {
			this.$set(this, 'tablaVencimientosForm', [...this.tablaVencimientos.map(i => ({...i}))])
			this.showModal('#tabalVencimientosModalGroup')
		},

		saveAllTV() {
			this.$set(this, 'tablaVencimientos', [...this.tablaVencimientosForm.map(i => ({...i}))])
			this.$notify({
				type: 'success',
				group: 'simulator',
				title: 'Success!',
				text: 'The record has been saved successfully'
			})
		},
		closeModal() {
			this.hiddenSteps()
			this.fillForm()
		},
		...mapMutations({
			_setDataChild: 'arceDetails/setDataChild'
		}),
		...mapActions({
			_updateArce: 'arceDetails/updateArce'
		})
		// ...mapMutations({
		// 	setShareholders: 'arceDetails/setShareholders'
		// })
	},
	mounted() {
		$('#formAnalysisFinancialRiskGroupModal').on('hide.bs.modal', this.closeModal)
	},

	beforeDestroy() {
		$('#formAnalysisFinancialRiskGroupModal').off('hide.bs.modal', this.closeModal)
	}
}
</script>
<template>
	<div style="">
		<div class="text-right py-2">
			<button class="s-btn s-btn--primary" @click="showModal('#formAnalysisFinancialRiskGroupModal')">
				Editar
			</button>
		</div>
		<div>
			<div class="s-collapse__header" data-toggle="collapse" data-target="#c_afrg_1" aria-expanded="true" aria-controls="c_afrg_1">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Información financiera grupo</span></h6>
				</div>
			</div>
			<div id="c_afrg_1" class="collapse show" aria-labelledby="headingOne">
				
				<div>
					<button class="s-btn s-btn--priamry s-btn---outline">Buscar Información financiera</button>
				</div>
				<div class="py-2 mb-5">
					<h6>Ejercicio Año Actual</h6>
					<table class="s-table w-100">
						<thead class="font-weight-bold">
							<tr>
								<td>Nombre</td>
								<td>Rating</td>
								<td>Total...</td>
								<td>FFPP</td>
								<td>Due...</td>
								<td>Ven...</td>
								<td>Ebit...</td>
								<td>Ben...</td>
								<td>Deu...</td>
								<td>Cob...</td>
								<td>Mar...</td>
								<td>Año</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(c, index) in getClients()">
								<td>{{c}}</td>
								<td>CCC+</td>
								<td>172,344...</td>
								<td>113,84...</td>
								<td>123,45...</td>
								<td>362,81...</td>
								<td>13,499...</td>
								<td>12,139...</td>
								<td>0</td>
								<td>1,124.9</td>
								<td>3.7%</td>
								<td>2019</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="py-2 mb-5">
					<h6>Ejercicio Año Anterior</h6>
					<table class="s-table w-100">
						<thead class="font-weight-bold">
							<tr>
								<td>Nombre</td>
								<td>Rating</td>
								<td>Total...</td>
								<td>FFPP</td>
								<td>Due...</td>
								<td>Ven...</td>
								<td>Ebit...</td>
								<td>Ben...</td>
								<td>Deu...</td>
								<td>Cob...</td>
								<td>Mar...</td>
								<td>Año</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(c, index) in getClients()">
								<td>{{c}}</td>
								<td>CCC+</td>
								<td>172,344...</td>
								<td>113,84...</td>
								<td>123,45...</td>
								<td>362,81...</td>
								<td>13,499...</td>
								<td>12,139...</td>
								<td>0</td>
								<td>1,124.9</td>
								<td>3.7%</td>
								<td>2019</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="d-flex flex-wrap py-2">
					
					<div class="col-12 py-2">
						<label class="d-block m-0">
							<p class="m-0"><span class="text-danger">*</span> Aspectos relevantes de la situación financiera y económica del Grupo</p>
							<textarea class="form-control form-control--disabled" disabled type="text" v-model="form.aspectosRelevantes">
							</textarea>
						</label>
					</div>
					
					<div class="col-12 py-2">
						<label class="d-block m-0">
							<p class="m-0"><span class="text-danger">*</span> Comentario sobre la solvencia financiera/Inmbiliaria</p>
							<textarea class="form-control form-control--disabled" disabled  type="text" v-model="form.comentariosSobreSolvenciaFinanciera">
							</textarea>
						</label>
					</div>
				</div>
			</div>

			<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c_afrg_2" aria-expanded="true" aria-controls="c_afrg_2">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Tabla de Vencimientos</span></h6>
				</div>
			</div>

			<div id="c_afrg_2" class="collapse hide" aria-labelledby="headingOne">
				<div class="d-flex flex-wrap">
					
					<div class="col-3 py-2">
						<label class="d-block">
							<p class="m-0"><span class="text-danger">*</span> ¿La tabla de Vencimiento está disponible?</p>
							<input class="form-control form-control--disabled" disabled  type="text" v-model="form.tablaVencimientosDisponible">
						</label>
					</div>

					<div class="col-12 py-2" v-if="form.tablaVencimientosDisponible == 'SI'">
						<div class="mb-3">
							<h3 class="text-center">Tabla Vencimientos</h3>
							<table class="s-table w-100 text-center">
								<thead>
									<tr>
										<th>NOMBRE</th>
										<th v-for="(ano, index) in getAnosList(showTableList)" :key="`tabla_vencimientos-${index}`">
											{{ano}}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(sh, index) in showTableList" :key="`tabla_vencimientos_2_${index}`">
										<td>{{sh.nombre}}</td>
										<td v-for="(ano, index) in getAnosList(showTableList)" :key="`tabla_vencimientos-${index}`">
											{{ ano == sh.ano ? sh.importe : 0 }}%
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div class="col-3 py-2">
						<label class="d-block m-0">
							<p class="m-0"><span class="text-danger">*</span> Comentario sobre la Deuda</p>
							<textarea class="form-control form-control--disabled" disabled type="text" v-model="form.comentariosSobreDeuda">
							</textarea>
						</label>
					</div>
					<div class="col-3 py-2">
						<label class="d-block m-0">
							<p class="m-0"><span class="text-danger">*</span> Número de Bancos</p>
							<input class="form-control  form-control--disabled" disabled type="text" v-model="form.numeroDeBancos">
						</label>
					</div>
				</div>
			</div>
			<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c_afrg_3" aria-expanded="true" aria-controls="c_afrg_3">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Fuera de Balance</span></h6>
				</div>
			</div>
			<div id="c_afrg_3" class="collapse hide" aria-labelledby="headingOne">
				<div class="d-flex flex-wrap">
					<div class="col-4 py-2">
						<label class="d-block">
							<p class="m-0"><span class="text-danger">*</span> ¿Maneja Coberturas?</p>
							<input class="form-control  form-control--disabled" disabled type="text" v-model="form.manejoCobertura">
							
						</label>
						<div class="py-2" v-if="form.manejoCobertura == 'SI'">
							<label class="d-block m-0">
								<p class="m-0"><span class="text-danger">*</span> Comentario sobre la Coberturas</p>
								<textarea class="form-control form-control--disabled" disabled type="text" v-model="form.comentarioManejoCobertura">
								</textarea>
							</label>
						</div>
					</div>
					<div class="col-4 py-2">
						<label class="d-block">
							<p class="m-0"><span class="text-danger">*</span> Información adicional relevante</p>
							<input class="form-control  form-control--disabled" disabled type="text" v-model="form.informacionAdicionalRelevante">
						</label>
						<div class="py-2" v-if="form.informacionAdicionalRelevante == 'SI'">
							<label class="d-block m-0">
								<p class="m-0"><span class="text-danger">*</span> Comentario sobre Información adicional relevante</p>
								<textarea class="form-control form-control--disabled" disabled type="text" v-model="form.comentarioInformacionAdicionalRelevante">
								</textarea>
							</label>
						</div>
					</div>
				</div>
			</div>
		
		</div>

		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<div class="modal s-modal fade" style="font-size: 0.8em" id="formAnalysisFinancialRiskGroupModal" tabindex="-1" role="dialog" aria-labelledby="formAnalysisFinancialRiskGroupModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl " role="document">
				<div class="modal-content">
				<div class="modal-header p-0">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="s-collapse__header" data-toggle="collapse" data-target="#c_afrg_m_1" aria-expanded="true" aria-controls="c_afrg_m_1">
						<div class="d-flex">
							<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Información financiera grupo</span></h6>
						</div>
					</div>
					<div id="c_afrg_m_1" class="collapse show" aria-labelledby="headingOne">
						
						<div>
							<button class="s-btn s-btn--priamry s-btn---outline">Buscar Información financiera</button>
						</div>
						<div class="py-2 mb-5">
							<h6>Ejercicio Año Actual</h6>
							<table class="s-table w-100">
								<thead class="font-weight-bold">
									<tr>
										<td>Nombre</td>
										<td>Rating</td>
										<td>Total...</td>
										<td>FFPP</td>
										<td>Due...</td>
										<td>Ven...</td>
										<td>Ebit...</td>
										<td>Ben...</td>
										<td>Deu...</td>
										<td>Cob...</td>
										<td>Mar...</td>
										<td>Año</td>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(c, index) in getClients()">
										<td>{{c}}</td>
										<td>CCC+</td>
										<td>172,344...</td>
										<td>113,84...</td>
										<td>123,45...</td>
										<td>362,81...</td>
										<td>13,499...</td>
										<td>12,139...</td>
										<td>0</td>
										<td>1,124.9</td>
										<td>3.7%</td>
										<td>2019</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="py-2 mb-5">
							<h6>Ejercicio Año Anterior</h6>
							<table class="s-table w-100">
								<thead class="font-weight-bold">
									<tr>
										<td>Nombre</td>
										<td>Rating</td>
										<td>Total...</td>
										<td>FFPP</td>
										<td>Due...</td>
										<td>Ven...</td>
										<td>Ebit...</td>
										<td>Ben...</td>
										<td>Deu...</td>
										<td>Cob...</td>
										<td>Mar...</td>
										<td>Año</td>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(c, index) in getClients()">
										<td>{{c}}</td>
										<td>CCC+</td>
										<td>172,344...</td>
										<td>113,84...</td>
										<td>123,45...</td>
										<td>362,81...</td>
										<td>13,499...</td>
										<td>12,139...</td>
										<td>0</td>
										<td>1,124.9</td>
										<td>3.7%</td>
										<td>2019</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="d-flex flex-wrap py-2">
							
							<div class="col-12 py-2">
								<label class="d-block m-0">
									<p class="m-0"><span class="text-danger">*</span> Aspectos relevantes de la situación financiera y económica del Grupo</p>
									<textarea class="form-control" type="text" v-model="form.aspectosRelevantes">
									</textarea>
									<characters-available :max="1000" :text="form.aspectosRelevantes"></characters-available>

								</label>
							</div>
							
							<div class="col-12 py-2">
								<label class="d-block m-0">
									<p class="m-0"><span class="text-danger">*</span> Comentario sobre la solvencia financiera/Inmbiliaria</p>
									<textarea class="form-control" type="text" v-model="form.comentariosSobreSolvenciaFinanciera">
									</textarea>
									<characters-available :max="1000" :text="form.comentariosSobreSolvenciaFinanciera"></characters-available>

								</label>
							</div>
						</div>
					</div>

					<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c_afrg_m_2" aria-expanded="true" aria-controls="c_afrg_m_2">
						<div class="d-flex">
							<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Tabla de Vencimientos</span></h6>
						</div>
					</div>

					<div id="c_afrg_m_2" class="collapse hide" aria-labelledby="headingOne">
						<div class="d-flex flex-wrap">
							
							<div class="col-3 py-2">
								<label class="d-block">
									<p class="m-0"><span class="text-danger">*</span> ¿La tabla de Vencimiento está disponible?</p>
									<div class="s-tf">
										<label class="s-tf__item">
											<input type="radio" name="afrg_tvd" value="SI" v-model="form.tablaVencimientosDisponible">
											<span class="s-tf__btn">SI</span>
										</label>
										<label class="s-tf__item" >
											<input type="radio" name="afrg_tvd" value="NO" v-model="form.tablaVencimientosDisponible">
											<span class="s-tf__btn">NO</span>
										</label>
									</div>
								</label>
							</div>

							<div class="col-12 py-2" v-if="form.tablaVencimientosDisponible == 'SI'">
								<div class="mb-3">
									<h3 class="text-center">Tabla Vencimientos</h3>
									<table class="s-table w-100 text-center">
										<thead>
											<tr>
												<th>NOMBRE</th>
												<th v-for="(ano, index) in getAnosList(showTableList)" :key="`tabla_vencimientos-${index}`">
													{{ano}}
												</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(sh, index) in showTableList" :key="`tabla_vencimientos_2_${index}`">
												<td>{{sh.nombre}}</td>
												<td v-for="(ano, index) in getAnosList(showTableList)" :key="`tabla_vencimientos-${index}`">
													{{ ano == sh.ano ? sh.importe : 0 }}%
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div>
									<button type="button" @click="showTablaForm"  class="s-btn s-btn--primary s-btn--outline" >Administrar Tabla de Vencimientos</button>
								</div>
							</div>

							<div class="col-3 py-2">
								<label class="d-block m-0">
									<p class="m-0"><span class="text-danger">*</span> Comentario sobre la Deuda</p>
									<textarea class="form-control" type="text" v-model="form.comentariosSobreDeuda">
									</textarea>
									<characters-available :max="1000" :text="form.comentariosSobreDeuda"></characters-available>

								</label>
							</div>
							<div class="col-3 py-2">
								<label class="d-block m-0">
									<p class="m-0"><span class="text-danger">*</span> Número de Bancos</p>
									<select class="form-control" type="text" v-model="form.numeroDeBancos">
										<option value=""></option>
										<option value="Trabaja con 4 o menos bancos">Trabaja con 4 o menos bancos</option>
										<option value="Trabaja con 5 a 10 banco">Trabaja con 5 a 10 banco</option>
										<option value="Trabaja con más de 10 Bancos">Trabaja con más de 10 Bancos</option>
									</select>
								</label>
							</div>
						</div>
					</div>
					<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c_afrg_m_3" aria-expanded="true" aria-controls="c_afrg_m_3">
						<div class="d-flex">
							<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Fuera de Balance</span></h6>
						</div>
					</div>
					<div id="c_afrg_m_3" class="collapse hide" aria-labelledby="headingOne">
						<div class="d-flex flex-wrap">
							<div class="col-4 py-2">
								<label class="d-block">
									<p class="m-0"><span class="text-danger">*</span> ¿Maneja Coberturas?</p>
									<div class="s-tf">
										<label class="s-tf__item">
											<input type="radio" name="afrg_mc" value="SI" v-model="form.manejoCobertura">
											<span class="s-tf__btn">SI</span>
										</label>
										<label class="s-tf__item" >
											<input type="radio" name="afrg_mc" value="NO" v-model="form.manejoCobertura">
											<span class="s-tf__btn">NO</span>
										</label>
									</div>
								</label>
								<div class="py-2" v-if="form.manejoCobertura == 'SI'">
									<label class="d-block m-0">
										<p class="m-0"><span class="text-danger">*</span> Comentario sobre la Coberturas</p>
										<textarea class="form-control" type="text" v-model="form.comentarioManejoCobertura">
										</textarea>
										<characters-available :max="500" :text="form.comentarioManejoCobertura"></characters-available>

									</label>
								</div>
							</div>
							<div class="col-4 py-2">
								<label class="d-block">
									<p class="m-0"><span class="text-danger">*</span> Información adicional relevante</p>
									<div class="s-tf">
										<label class="s-tf__item">
											<input type="radio" name="afrg_iar" value="SI" v-model="form.informacionAdicionalRelevante">
											<span class="s-tf__btn">SI</span>
										</label>
										<label class="s-tf__item" >
											<input type="radio" name="afrg_iar" value="NO" v-model="form.informacionAdicionalRelevante">
											<span class="s-tf__btn">NO</span>
										</label>
									</div>
								</label>
								<div class="py-2" v-if="form.informacionAdicionalRelevante == 'SI'">
									<label class="d-block m-0">
										<p class="m-0"><span class="text-danger">*</span> Comentario sobre Información adicional relevante</p>
										<textarea class="form-control" type="text" v-model="form.comentarioInformacionAdicionalRelevante">
										</textarea>
										<characters-available :max="500" :text="form.comentarioInformacionAdicionalRelevante"></characters-available>

									</label>
								</div>
							</div>
						</div>
					</div>
				
				</div>
				<div class="modal-footer">
					<button type="button" class="s-btn s-btn--outline" data-dismiss="modal">Cancelar</button>
					<button type="button" class="s-btn s-btn--primary" data-dismiss="modal" @click="save">Guardar</button>
				</div>
				</div>
			</div>
		</div>

		<!-- ==================== -->
		<!-- ==================== -->
		<!-- ==================== -->
		<div class="modal s-modal fade" id="tabalVencimientosModalGroup" tabindex="-1" role="dialog" aria-labelledby="tabalVencimientosModalGroupLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
				<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title w-100 text-center" id="tabalVencimientosModalGroupLabel">Administrar Tabla de Vencimientos</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="d-flex" v-for="(tv, index) in tablaVencimientosForm" :key="`tv_${index}`">
						<div class="col-4 mt-auto">
							<p class="m-0"> 
								Nombre
							</p>
							<div class="position-relative">
								<div class="position-relative">
									<div class="s-input" data-v-step="cs1">
										<input v-if="!tv.nombre" class="form-control w-100" type="text" v-model="tv.query">
										<input v-else class="form-control w-100" disabled type="text" v-model="tv.nombre">
										<svg-icon class="mx-2" name="search" />
										<span class="m-0" @click="tv.nombre = ''" v-if="tv.nombre">
											<svg-icon class="mx-2 s-input__delete" name="close" />
										</span>
									</div>
									
								</div>
								<div v-if="tv.query" class="s-query-result">
									<div class="d-flex" @click="tv.nombre = tv.query, tv.query = ''">
										<button class="s-query-btn my-auto">
											<svg-icon name="add" />
										</button>
										<div class="ml-2 my-auto">
											<p class="small m-0">Create new arce...</p>
											<p class="m-0 small">{{tv.query}}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					
						<div class="col-4 mt-auto">
							<p class="m-0"> 
								Año
							</p>
							<input class="form-control w-100" type="text" v-model="tv.ano">
						</div>
						<div class="col-3 mt-auto">
							<p class="m-0"> 
								Importe
							</p>
							<input class="form-control w-100" type="text" v-model="tv.importe">
						</div>
						<div class="col-1 mt-auto">
							<button v-if="tablaVencimientosForm.length > 1" @click="deleteItem(index)" class="s-btn s-btn--transparent">
								<svg-icon name="delete" />
							</button>
						</div>
					</div>
					<div class="text-right">
						<button @click="addNew" class="s-query-btn my-auto mr-5">
							<svg-icon name="add" />
						</button>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" @click="saveAllTV" class="s-btn s-btn--primary">Save all</button>
				</div>
				</div>
			</div>
		</div>

	</div>
</template>
